export class PathConstants {

  public static NO_OF_DAYS_TO_FETCH_RECENT_CASES = 20;
  public static getUserDetailsPath(userId: string): string {
    return `/case-vue-users/${userId}`;
  }

  public static getCasesPath(userId: string): string {
    return `/case-vue-users/${userId}/cases`;
  }

  public static getCaseUsersPath(): string {
    return `/case-vue-users`;
  }

  public static getStoragePath(userId: string, caseId: string): string {
    return `case-vue-users/${userId}/cases/${caseId}`;
  }

  public static getFileCollectionPath(
    userId: string,
    caseId: string
  ): string {
    return `case-vue-users/${userId}/cases/${caseId}/documents`;
  }

  public static getFilePath(
    userId: string,
    caseId: string,
    fileId: string,
  ): string {
    return `case-vue-users/${userId}/cases/${caseId}/documents/${fileId}`;
  }


  
}
